.fluidImg{
	max-width: 100%;
	height: auto;
	width:auto; /* for ie8 */
}
.tCenter{
	text-align:center;
}

.mAuto{
	margin:auto;
}
.left{
	float:left;
}
.right{
	float:right;
}
.tl{
	text-align: left;
}
.tr{
	text-align: right;
}
.clear{
	clear: both
}
.clf:after{/*for modern browser*/
	content:".";
	display: block;
	height:0px;
	clear:both;
	line-height:0;
	visibility:hidden;
}

.clf {
	zoom:1;/*for IE 5.5-7*/
}

a{
	color:#333;
	text-decoration:none;
}
img{
	vertical-align:bottom;
}
.hover{
	transition:all 0.2s linear;
	display:inline-block;
	&:hover{
		opacity:0.5;
	}
}
/* ===========================================================
// sprite
===========================================================*/
.sprite{
    // background: url("../img/sprite.png") no-repeat;
    // background-position: 0 -0px;
    // background-size:500px 500px;
    // display:inline-block;
    // @include spPos(104px,35px,-55px,0px);
    // @include hideout_text;
  }
