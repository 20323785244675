@charset "UTF-8";
/* General */
.float-list, .cf {
  *zoom: 1;
}
.float-list:after, .cf:after {
  content: "";
  display: block;
  clear: both;
  height: 0;
}

.float-list li {
  float: left;
}

/* Base */
*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  font-size: 1.4rem;
  line-height: 1.9;
  font-size: 14px;
  font-family: 'Lucida Grande', 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', 'Meiryo', 'メイリオ', 'sans-serif';
  color: #333;
  background: #F5F5F5;
}

a:link, a:visited {
  color: #333;
  text-decoration: none;
}
a:hover, a:active, a:focus {
  text-decoration: underline;
}
a:hover img, a:active img, a:focus img {
  opacity: 0.7;
  filter: alpha(opacity=70);
  -ms-filter: "alpha(opacity=70)";
}

img {
  max-width: 100%;
  height: auto;
  box-sizing: content-box;
  vertical-align: middle;
  line-height: 1;
}

h1, h2, h3, h4, h5, h6 {
  max-height: 100%;
}

input[type="button"], input[type="submit"], input[type="reset"], label {
  cursor: pointer;
}

.header-logo a:hover img,
.footer-logo a:hover img {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: "alpha(opacity=100)";
}

/* Layout */
/*==============================
共通要素
==============================*/
.wrap {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.left-box {
  float: left;
}

.right-box {
  float: right;
}

.white-box {
  padding: 2rem;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
}

.cat {
  display: inline-block;
  padding: .1rem 1rem;
  margin: 0 2rem 1rem 0;
  color: #FFF;
  font-size: 1.2rem;
  font-weight: 800;
  background: #EC9000;
}
.cat a {
  color: #FFF;
}

.date {
  font-size: 1.2rem;
  color: #555966;
}

.ttl {
  margin: 0 0 1rem;
  color: #555966;
  font-size: 2.1rem;
  font-weight: 800;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.txt {
  font-size: 1.2rem;
  line-height: 1.5;
}

input[type=text],
input[type=email],
input[type=url],
input[type=tel] {
  height: 3.8rem;
  width: 100%;
  padding: 0 1rem;
  font-size: 1.2rem;
  border: 1px solid #d1d1d1;
}

input:not([type=submit]):focus,
textarea:focus {
  background: #fff8e7;
}

.sp {
  display:none;
}


/*==============================
ヘッダー
==============================*/
.l-header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 105px;
  z-index: 10;
  color: #FFF;
  background: #222222;
}
.l-header .h1-area {
  margin: 0 0 2rem;
  padding: .1rem 0 0;
  height: 2.2rem;
  color: #fff;
  font-size: 10px;
  background: #000;
}
.l-header .header-logo {
  float: left;
  width: 20%;
  padding: 0 0 2rem 0;
}
.l-header .right-box {
  width: 78%;
}
.l-header .right-box .menu-trigger {
  display: none;
}
@media screen and (min-width: 769px) {
  .l-header {
    /* 769px以上の場合 */
  }
  .l-header .sp-nav {
    display: none;
  }
  .l-header .l-nav {
    float: left;
    display: flex;
    justify-content: space-between;
    // display: table;
    width: 100%;
  }
  .l-header .l-nav .nav-list {
    // display: table-cell;
    margin-top: 10px;
    width: 70%;
  }
  .l-header .l-nav .nav-list > li {
    // float: left;
    display: inline-block;
    position: relative;
    margin: 0;

    margin-right: 18px;
    // display: block;
    // border-left: 1px solid #494949;
  }
  .l-header .l-nav .nav-list > li:last-child {
    // border-right: 1px solid #494949;
  }
  .l-header .l-nav .child {
    display: none;
    width: 100%;
    position: absolute;
    padding: 20px 0 0;
    z-index: 5;
  }
  .l-header .l-nav li a{
    text-decoration: none;
  }
  .l-header .l-nav .child li {
    background: rgba(0, 0, 0, 0.72);
  }
  .l-header .l-nav .child li a {
    padding: 10px;
    width: 100%;
    display: block;
    line-height: 1.5;
    font-size: 12px;
    color: #fff;
  }
  .l-header .l-nav .child li a:hover, .l-header .l-nav .child li a:active {
    text-decoration: none;
    background: rgba(0, 0, 0, 0.59);
  }
  .l-header .header-sns {
    display: table-cell;
  }
  .l-header .header-sns li {
    float: left;
    width: 33%;
    padding-left: 1.8rem;
  }
}

.l-mv {
  margin: 10.5rem 0 0;
  display: table;
  height: 200px;
  width: 100%;
  background: url(../img/layout/header/mv_bg.png) no-repeat center top;
}
.l-mv > div {
  display: table-cell;
  vertical-align: middle;
}

/*==============================
コンテンツ
==============================*/
.l-main {
  float: left;
  width: 100%;
  margin: 0 -370px 0 0;
  padding: 0 400px 0 0;
}
.l-main .post-box {
  padding: 0;
  margin: 0 0 10px;
}
.l-main .post-box a:hover,
.l-main .post-box a:active,
.l-main .post-box a:focus {
  text-decoration: none;
}
.l-main .post-box a {
  display: block;
  padding: 2rem;
}
.l-main .post-box:hover {
  transition: .5s;
  background: #fff8e7;
}
.l-main .wp-pagenavi {
  text-align: center;
  margin: 4rem 0 0;
}
.l-main .wp-pagenavi .pages {
  display: none;
}
.l-main .wp-pagenavi a, .l-main .wp-pagenavi .current {
  display: inline-block;
  height: 3rem;
  width: 3rem;
  padding: .1rem 0;
  border: 1px solid #e1e1e1;
  background: #fff;
}
.l-main .wp-pagenavi a:hover {
  text-decoration: none;
  transition: .5s;
  background: #fff8e7;
}
.l-main .wp-pagenavi .current {
  color: #FFF;
  background: #0C0C0C;
}
.l-main .wp-pagenavi .previouspostslink,
.l-main .wp-pagenavi .nextpostslink {
  padding: 0;
  font-weight: 900;
}

.l-side {
  float: right;
  width: 370px;
}
.l-side .side-box {
  margin: 0 0 3rem;
}
.l-side .search {
  max-width: 26rem;
}
.l-side .seach-btn {
  width: 6rem;
  height: 3.8rem;
  border: none;
  background: url(../img/layout/side/search_btn.png) no-repeat center;
  background-size: cover;
}
.l-side h3 {
  padding: 0 0 1rem;
  margin: 0 0 1rem;
}
.l-side .dash-head {
  background: url(../img/module/dash.png) repeat-x left bottom;
}
.l-side .cat-list a {
      display: inline-block;
    padding: 3px 8px 0;
  line-height: 1.5;
  font-size: 1.2rem;
  color: #2e74bb;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.tagcloud a:hover {
    background: #fff8e7;
    text-decoration: none;
}
.l-side .mailmaga-box p {
  margin: 0 0 .5rem;
  font-size: 1.2rem;
  color: #555966;
}
.l-side .mailmaga-box input:not(:last-child) {
  margin: 0 0 2rem;
}
.l-side .mailmaga-box input:last-child {
  margin: 1rem 0;
  width: 24rem;
  height: 3.8rem;
  border: none;
  background: #9D1715 url(../img/layout/side/btn_entry.png) no-repeat center;
}
.l-side .mailmaga-box .submit-box {
  text-align: center;
}
.l-side .ranking-box .ranking-list {
  counter-reset: item;
}
.l-side .ranking-box li:before {
  display: inline-block;
  margin-right: 2rem;
  width: 3.5rem;
  height: 3.5rem;
  font-size: 1.8rem;
  color: #FFF;
  vertical-align: top;
  text-align: center;
  content: counters(item,"-");
  counter-increment: item;
  background: #c5c5c5;
}
.l-side .ranking-box li {
  margin: 0 0 2.5rem;
}
.l-side .ranking-box li p {
  margin: -.3rem 0 0 6rem;
  color: #555965;
}
.l-side .ranking-box li:first-child::before {
  background: #ecc200;
}
.l-side .ranking-box li:nth-child(2)::before {
  background: #636363;
}
.l-side .ranking-box li:nth-child(3)::before {
  background: #b38369;
}
.l-side .ranking-box .cat {
  font-size: 1.2rem;
}
.l-side .ranking-box .ttl {
  font-size: 1.4rem;
  white-space: normal;
}

h3.h-sns {
  color: #fff;
    padding: 1rem;
    font-size: 1.8rem;
    font-weight: 900;
    border: solid 3px #fff;
}
.h-facebook {
    background: #3b5998;
}
.h-twitter {
  background: #00aced;
}

.l-contents {
  margin: 30px auto 35px;
}

/*==============================
フッター
==============================*/
.l-footer {
  padding: 4rem 0;
  text-align: center;
  background: #222;
}
.l-footer .footer-navi-list {
  display: table;
  width: 100%;
  max-width: 65rem;
  margin: 0 auto 5rem;
  vertical-align: middle;
}
.l-footer .footer-navi-list li {
  display: table-cell;
}
.l-footer .footer-navi-list li a {
  display: block;
  color: #fff;
}
.l-footer .footer-sns {
  display: table;
  margin: 4rem auto 0;
}
.l-footer .footer-sns li {
  display: table-cell;
}
.l-footer .footer-sns li a {
  padding: 0 1.3rem;
}
.l-footer .add {
  padding: 0 0 1rem;
  font-size: 1rem;
  color: #7f7f7f;
}

@media screen and (max-width: 768px) {
  /* 768px以下の場合 */

  .sp {
    display:block;
   }
  .pc {
    display:none;
   }

  /*==============================
  ヘッダー
  ==============================*/
  .l-header .header-logo {
    width: 80%;
  }
  .l-header .right-box {
    width: 20%;
  }
  .l-header .right-box .menu-trigger,
  .l-header .right-box .menu-trigger span {
    display: inline-block;
    transition: all .4s;
    box-sizing: border-box;
  }
  .l-header .right-box .menu-trigger {
    display: block;
    position: relative;
    float: right;
    width: 50px;
    height: 44px;
  }
  .l-header .right-box .menu-trigger span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #555965;
    border-radius: 4px;
  }
  .l-header .right-box .menu-trigger span:nth-of-type(1) {
    top: 0;
  }
  .l-header .right-box .menu-trigger span:nth-of-type(2) {
    top: 20px;
  }
  .l-header .right-box .menu-trigger span:nth-of-type(3) {
    bottom: 0;
  }
  .l-header .right-box .menu-trigger.active span:nth-of-type(1) {
    transform-origin: 100% 0;
    -webkit-transform: translateY(0px) rotate(-45deg);
    transform: translateY(0px) rotate(-45deg);
  }
  .l-header .right-box .menu-trigger.active span:nth-of-type(2) {
    opacity: 0;
  }
  .l-header .right-box .menu-trigger.active span:nth-of-type(3) {
    transform-origin: 100% 100%;
    -webkit-transform: translateY(-0px) rotate(45deg);
    transform: translateY(-0px) rotate(45deg);
  }
  .l-header .l-nav {
    display: none;
  }
  .l-header .sp-nav {
    display: none;
    position: absolute;
    width: 100%;
    top: 10rem;
    right: 0;
    z-index: 5;
  }
  .l-header .sp-nav .nav-list {
    background: rgba(0, 0, 0, 0.9);
    display: block;
  }
  .l-header .sp-nav .nav-list > li {
    width: 100%;
    text-align: center;
    border: none;
  }
  .l-header .sp-nav .nav-list > li a {
    display: block;
    width: 100%;
  }
  .l-header .sp-nav .child {
    display: none;
    position: relative;
  }
  .l-header .sp-nav .child a {
  }

  .l-mv {
    max-height: 260px;
    height: 35vw;
  }

  /*==============================
  コンテンツ
  ==============================*/
  .l-main {
    float: none;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .l-side {
    float: none;
    margin: 4rem 0 0;
    width: 100%;
  }
  .l-side .side-top,
  .l-side .facebook-box,
  .l-side .twitter-box {
    text-align: center;
  }
  .l-side .searchform {
    display: table;
  width:100%;
  }
  .l-side .search {
    display: table-cell;
    max-width: 81%;
  }
  .l-side .seach-btn {
    display: table-cell;
    width: 19%;
    background-color: #9D1715;
    background-size: contain;
  }
}

.l-copyright small{
  display:inline-block;
  margin:35px 0 10px;
  color: #7f7f7f;
  font-size: 10px;
}