
$color01:#ccc;
.form{
	margin-top: 30px;
	form{
		label{
			display: block;
			margin-right: 10px;
		}
		input{
			vertical-align:1px;
		}
		input[type='text'],
		input[type='search'],
		input[type='email'],
		input[type='password'],
		{

		}
		button{
			cursor: pointer;
			border: 0;
			background-color: #000;
			// background-color: rgba($brandColor,1);
		}
	}
	textarea{
		font-size: 14px;

		box-sizing: border-box;
		width: 100%;
		padding: 10px 10px;
		min-height:100px;
		border: 1px solid $color01;
		// background-color: #fff;
		border-radius: 3px;
		appearance: none;
		// color: #fff;
		box-sizing: border-box;
		&::-webkit-input-placeholder
		{
			color: #ccc;
		}
	}
	.form__inner{
		// display: flex;
		flex-wrap:wrap;
		width: 100%;
	}
	.form__elm{
		// display: flex;
		width: 100%;
		margin-bottom: 20px;
	}
	.form__name{
		font-size: 14px;
		margin-bottom: 3px;
		font-weight: bold;
	}
	.form__val{
		// width: 600px;
	}
	.form__submit{
		margin-top: 0px;
		text-align: center;
		input[type='submit']{
			// width: 250px;
			// padding:15px;
			// box-sizing: border-box;
			// color:#fff;
			// font-size: 16px;
			// border-radius:3px;
			// background-color: #000;
			// cursor: pointer;
			background: #f53243;
			-webkit-appearance: none;
			border-radius: 0;
			color: #FFF;
			border: none;
			border-bottom: 3px solid #c11121;
			padding: 10px 20px;
			margin: 20px 0;
			cursor: pointer;
			width: 60%;
			font-size: 16px;
			display: inline-block;
			&:hover{
				// border-bottom: 0px;

			}
		}
		button{
			width: 250px;
			padding:15px;
			box-sizing: border-box;
			color:#fff;
			font-size: 16px;
			border-radius:3px;
		}
	}



















	input[type=radio], input[type=checkbox] {
		display: none;
	}
	input[type=radio]:checked + .radio:before {
		opacity: 1;
	}
	input[type=checkbox]:checked + .checkbox:before {
		opacity: 1;
	}

	.radio{
		box-sizing: border-box;
		-webkit-transition: background-color 0.2s linear;
		transition: background-color 0.2s linear;
		position: relative;
		display: inline-block;
		// margin: 0 20px 8px 0;
		// padding: 12px 12px 12px 42px;
		padding-left: 20px;
		border-radius: 8px;
		// background-color: #f6f7f8;
		vertical-align: middle;
		cursor: pointer;
		&:after{
			-webkit-transition: border-color 0.2s linear;
			transition: border-color 0.2s linear;
			position: absolute;
			top: 50%;
			left: 0px;
			display: block;
			margin-top: -9px;
			width: 15px;
			height: 15px;
			border: 1px solid rgba(#000,0.3);
			background-color: #fff;
			border-radius: 100%;
			content: '';
		}
		&:before {
			-webkit-transition: opacity 0.2s linear;
			transition: opacity 0.2s linear;
			position: absolute;
			top: 50%;
			left: 5px;
			display: block;
			margin-top: -4px;
			width: 7px;
			height: 7px;
			background: #000;
			content: '';
			opacity: 0;
			z-index: 10;
			border-radius: 10px;
		}
	}
	.checkbox {
		box-sizing: border-box;
		-webkit-transition: background-color 0.2s linear;
		transition: background-color 0.2s linear;
		position: relative;
		display: inline-block;
		padding-top: 5px;
		padding-left: 26px;
		border-radius: 8px;
		vertical-align: middle;
		cursor: pointer;
		span{
			display: inline-block;
			padding-top: 5px;
		}
		&:after {
			-webkit-transition: border-color 0.2s linear;
			transition: border-color 0.2s linear;
			position: absolute;
			top: 50%;
			left: 0px;
			display: block;
			margin-top: -9px;
			width: 20px;
			height: 20px;
			border: 1px solid rgba(#000,0.3);
			background-color: #fff;
			border-radius: 2px;
			content: '';
		}
		&:before {
			transition: opacity 0.2s linear;
			position: absolute;
			top: 50%;
			left: 7px;
			display: block;
			margin-top: -8px;
			width: 5px;
			height: 13px;
			border-right: 3px solid #000;
			border-bottom: 3px solid #000;
			content: '';
			opacity: 0;

			transform: rotate(45deg);
			z-index: 10;
		}
	}
}







