.l-header{
	background-color: #fff;
	padding: 14px;
	padding-top: 6px;
	height: 80px;
	min-width: 1300px;
	.header__banner{
		margin-top: -8px;
		margin-right: 0px;
		width: 440px;
		a{
			display: inline-block;
			&:first-child{
				margin-right: 5px;
			}
		}
	}
}
.l-header .h1-area {
	margin: 0;
	padding:0;
	height: auto;
	color: #736f6f;
	font-size: 10px;
	background-color: transparent;
	margin-bottom: 2px;
}
.l-nav{
	display: flex!important;
}
.l-mv{
	margin-top: 81px;
}
.l-header .l-nav .nav-list{
	margin-top: 1px;
}
.l-header .l-nav .child{
	padding-top: 27px;
}
.mv{

	.mv__inner{
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}
	.mv__image{
		width: 690px;
	}
	.mv__banner{
		width: 470px;
		position: relative;
		left: 8px;
		padding-top: 20px;
		a{
			display: inline-block;
			margin-right: -5px;
		}
	}
}
.page{
	background-color: #fff;

	.page__inner{
		padding: 2rem;
	}
}



.relation{
	margin-top: 60px;
	.relation__elm{
		margin-bottom: 20px;
		padding: 2rem;
		-webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
		box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
		background: #fff;
		a{
			text-decoration: none;
		}
	}
}




.diagnosis{
	padding-top: 20px;
	font-size: 14px;
	.diagnosis__image{
		text-align: center;
	}
	.form{
		padding: 0 20px;
	}
	#trouble{
		margin-top: 80px;
		border-top: 4px solid #0ba1d9;
		width: calc(100% + 40px);
		margin-left: -20px;
	}
	#trouble .ico_tro{
		margin-top: 20px;
		text-align: center;
	}
	#trouble .ico_tro img{
		width: 60px;
		margin-top: -40px;
		position: relative;
	}
	#trouble .container{ margin: 0 40px; }
	#trouble .img{
		position: relative;
		margin-top: -80px;
	}
	#trouble .msg{ width: 65%;}
	#trouble .msg h3{
		font-size: 180%;
		font-weight: bold;
		color: #0ba1d9;
		margin: 20px 0  15px 0;
	}
	#trouble .msg li{
		font-size: 150%;
		font-weight: bold;
		background: url(/wp-content/themes/mts_moneyflow_2016/assets/img/page/diagnosis/ico_check.png) no-repeat left top;
		background-size: 25px;
		padding-left: 40px;
		border-bottom: 1px solid #ddd;
		padding-bottom: 15px;
		margin-bottom: 15px;
	}
	#trouble .tro_hilig{
		background: #f1f1f1;
		padding: 30px;
		text-align: center;
	}
	#trouble .tro_hilig p{

		font-size: 150%;
		font-weight: bold;
	}
	#form_trouble .txt_troble_blue{
		margin-top: 20px;
		font-size: 160%;
		font-weight: bold;
		color: #0ba1d9;
		text-align: center;
	}
	#form_trouble .diagnosis{
		text-align: center;
		background: url(/wp-content/themes/mts_moneyflow_2016/assets/img/page/diagnosis/arrow05.png) no-repeat center bottom;
		background-size: auto 25px;
		padding-bottom: 25px;
	}
	#form_trouble .diagnosis span{
		display: inline-block;
		font-size: 220%;
		font-weight: bold;
		color: #f8bb00;
		border: 2px solid #f8bb00;
		padding:10px 40px;
	}
	#form_trouble .txt_diag{
		font-weight: bold;
		text-align: center;
		font-size: 120%;
		margin: 15px 0 0 0 ;
	}
	.pic_l .img, .pic_r .msg {
		float: left;
		padding-right:8px;
	}
	.pic_l .msg, .pic_r .img {
		float: right;
	}

	.flow_picl .img{ float:left; margin-right: 15px; margin-bottom: 5px;}
	.flow_picr .img{ float:right; margin-left: 15px; margin-bottom: 5px;}

}

