@charset "UTF-8";
.fluidImg {
  max-width: 100%;
  height: auto;
  width: auto;
  /* for ie8 */ }

.tCenter {
  text-align: center; }

.mAuto {
  margin: auto; }

.left {
  float: left; }

.right {
  float: right; }

.tl {
  text-align: left; }

.tr {
  text-align: right; }

.clear {
  clear: both; }

.clf:after {
  /*for modern browser*/
  content: ".";
  display: block;
  height: 0px;
  clear: both;
  line-height: 0;
  visibility: hidden; }

.clf {
  zoom: 1;
  /*for IE 5.5-7*/ }

a {
  color: #333;
  text-decoration: none; }

img {
  vertical-align: bottom; }

.hover {
  transition: all 0.2s linear;
  display: inline-block; }
  .hover:hover {
    opacity: 0.5; }

/* ===========================================================
// sprite
===========================================================*/
@keyframes rumble {
  0% {
    transform: rotate(0deg) translate(0, 0); }
  12.5% {
    transform: rotate(0.2deg) translate(1px, -1px); }
  25% {
    transform: rotate(0.4deg) translate(0px, 1px); }
  37.5% {
    transform: rotate(0.2deg) translate(-1px, 0); }
  50% {
    transform: rotate(0deg) translate(0, 0); }
  62.5% {
    transform: rotate(-0.2deg) translate(1px, 0); }
  75% {
    transform: rotate(-0.4deg) translate(0, 1px); }
  87.5% {
    transform: rotate(-0.2deg) translate(-1px, -1px); }
  100% {
    transform: rotate(0deg) translate(0, 0); } }

/* ===========================================================
// temp
===========================================================*/
/* General */
.float-list, .cf {
  *zoom: 1; }

.float-list:after, .cf:after {
  content: "";
  display: block;
  clear: both;
  height: 0; }

.float-list li {
  float: left; }

/* Base */
*,
*:before,
*:after {
  box-sizing: inherit; }

html {
  box-sizing: border-box;
  font-size: 62.5%; }

body {
  font-size: 1.4rem;
  line-height: 1.9;
  font-size: 14px;
  font-family: 'Lucida Grande', 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', 'Meiryo', 'メイリオ', 'sans-serif';
  color: #333;
  background: #F5F5F5; }

a:link, a:visited {
  color: #333;
  text-decoration: none; }

a:hover, a:active, a:focus {
  text-decoration: underline; }

a:hover img, a:active img, a:focus img {
  opacity: 0.7;
  filter: alpha(opacity=70);
  -ms-filter: "alpha(opacity=70)"; }

img {
  max-width: 100%;
  height: auto;
  box-sizing: content-box;
  vertical-align: middle;
  line-height: 1; }

h1, h2, h3, h4, h5, h6 {
  max-height: 100%; }

input[type="button"], input[type="submit"], input[type="reset"], label {
  cursor: pointer; }

.header-logo a:hover img,
.footer-logo a:hover img {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: "alpha(opacity=100)"; }

/* Layout */
/*==============================
共通要素
==============================*/
.wrap {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px; }

.left-box {
  float: left; }

.right-box {
  float: right; }

.white-box {
  padding: 2rem;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
  background: #fff; }

.cat {
  display: inline-block;
  padding: .1rem 1rem;
  margin: 0 2rem 1rem 0;
  color: #FFF;
  font-size: 1.2rem;
  font-weight: 800;
  background: #EC9000; }

.cat a {
  color: #FFF; }

.date {
  font-size: 1.2rem;
  color: #555966; }

.ttl {
  margin: 0 0 1rem;
  color: #555966;
  font-size: 2.1rem;
  font-weight: 800;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.txt {
  font-size: 1.2rem;
  line-height: 1.5; }

input[type=text],
input[type=email],
input[type=url],
input[type=tel] {
  height: 3.8rem;
  width: 100%;
  padding: 0 1rem;
  font-size: 1.2rem;
  border: 1px solid #d1d1d1; }

input:not([type=submit]):focus,
textarea:focus {
  background: #fff8e7; }

.sp {
  display: none; }

/*==============================
ヘッダー
==============================*/
.l-header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 105px;
  z-index: 10;
  color: #FFF;
  background: #222222; }

.l-header .h1-area {
  margin: 0 0 2rem;
  padding: .1rem 0 0;
  height: 2.2rem;
  color: #fff;
  font-size: 10px;
  background: #000; }

.l-header .header-logo {
  float: left;
  width: 20%;
  padding: 0 0 2rem 0; }

.l-header .right-box {
  width: 78%; }

.l-header .right-box .menu-trigger {
  display: none; }

@media screen and (min-width: 769px) {
  .l-header {
    /* 769px以上の場合 */ }
  .l-header .sp-nav {
    display: none; }
  .l-header .l-nav {
    float: left;
    display: flex;
    justify-content: space-between;
    width: 100%; }
  .l-header .l-nav .nav-list {
    margin-top: 10px;
    width: 70%; }
  .l-header .l-nav .nav-list > li {
    display: inline-block;
    position: relative;
    margin: 0;
    margin-right: 18px; }
  .l-header .l-nav .child {
    display: none;
    width: 100%;
    position: absolute;
    padding: 20px 0 0;
    z-index: 5; }
  .l-header .l-nav li a {
    text-decoration: none; }
  .l-header .l-nav .child li {
    background: rgba(0, 0, 0, 0.72); }
  .l-header .l-nav .child li a {
    padding: 10px;
    width: 100%;
    display: block;
    line-height: 1.5;
    font-size: 12px;
    color: #fff; }
  .l-header .l-nav .child li a:hover, .l-header .l-nav .child li a:active {
    text-decoration: none;
    background: rgba(0, 0, 0, 0.59); }
  .l-header .header-sns {
    display: table-cell; }
  .l-header .header-sns li {
    float: left;
    width: 33%;
    padding-left: 1.8rem; } }

.l-mv {
  margin: 10.5rem 0 0;
  display: table;
  height: 200px;
  width: 100%;
  background: url(../img/layout/header/mv_bg.png) no-repeat center top; }

.l-mv > div {
  display: table-cell;
  vertical-align: middle; }

/*==============================
コンテンツ
==============================*/
.l-main {
  float: left;
  width: 100%;
  margin: 0 -370px 0 0;
  padding: 0 400px 0 0; }

.l-main .post-box {
  padding: 0;
  margin: 0 0 10px; }

.l-main .post-box a:hover,
.l-main .post-box a:active,
.l-main .post-box a:focus {
  text-decoration: none; }

.l-main .post-box a {
  display: block;
  padding: 2rem; }

.l-main .post-box:hover {
  transition: .5s;
  background: #fff8e7; }

.l-main .wp-pagenavi {
  text-align: center;
  margin: 4rem 0 0; }

.l-main .wp-pagenavi .pages {
  display: none; }

.l-main .wp-pagenavi a, .l-main .wp-pagenavi .current {
  display: inline-block;
  height: 3rem;
  width: 3rem;
  padding: .1rem 0;
  border: 1px solid #e1e1e1;
  background: #fff; }

.l-main .wp-pagenavi a:hover {
  text-decoration: none;
  transition: .5s;
  background: #fff8e7; }

.l-main .wp-pagenavi .current {
  color: #FFF;
  background: #0C0C0C; }

.l-main .wp-pagenavi .previouspostslink,
.l-main .wp-pagenavi .nextpostslink {
  padding: 0;
  font-weight: 900; }

.l-side {
  float: right;
  width: 370px; }

.l-side .side-box {
  margin: 0 0 3rem; }

.l-side .search {
  max-width: 26rem; }

.l-side .seach-btn {
  width: 6rem;
  height: 3.8rem;
  border: none;
  background: url(../img/layout/side/search_btn.png) no-repeat center;
  background-size: cover; }

.l-side h3 {
  padding: 0 0 1rem;
  margin: 0 0 1rem; }

.l-side .dash-head {
  background: url(../img/module/dash.png) repeat-x left bottom; }

.l-side .cat-list a {
  display: inline-block;
  padding: 3px 8px 0;
  line-height: 1.5;
  font-size: 1.2rem;
  color: #2e74bb;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out; }

.tagcloud a:hover {
  background: #fff8e7;
  text-decoration: none; }

.l-side .mailmaga-box p {
  margin: 0 0 .5rem;
  font-size: 1.2rem;
  color: #555966; }

.l-side .mailmaga-box input:not(:last-child) {
  margin: 0 0 2rem; }

.l-side .mailmaga-box input:last-child {
  margin: 1rem 0;
  width: 24rem;
  height: 3.8rem;
  border: none;
  background: #9D1715 url(../img/layout/side/btn_entry.png) no-repeat center; }

.l-side .mailmaga-box .submit-box {
  text-align: center; }

.l-side .ranking-box .ranking-list {
  counter-reset: item; }

.l-side .ranking-box li:before {
  display: inline-block;
  margin-right: 2rem;
  width: 3.5rem;
  height: 3.5rem;
  font-size: 1.8rem;
  color: #FFF;
  vertical-align: top;
  text-align: center;
  content: counters(item, "-");
  counter-increment: item;
  background: #c5c5c5; }

.l-side .ranking-box li {
  margin: 0 0 2.5rem; }

.l-side .ranking-box li p {
  margin: -.3rem 0 0 6rem;
  color: #555965; }

.l-side .ranking-box li:first-child::before {
  background: #ecc200; }

.l-side .ranking-box li:nth-child(2)::before {
  background: #636363; }

.l-side .ranking-box li:nth-child(3)::before {
  background: #b38369; }

.l-side .ranking-box .cat {
  font-size: 1.2rem; }

.l-side .ranking-box .ttl {
  font-size: 1.4rem;
  white-space: normal; }

h3.h-sns {
  color: #fff;
  padding: 1rem;
  font-size: 1.8rem;
  font-weight: 900;
  border: solid 3px #fff; }

.h-facebook {
  background: #3b5998; }

.h-twitter {
  background: #00aced; }

.l-contents {
  margin: 30px auto 35px; }

/*==============================
フッター
==============================*/
.l-footer {
  padding: 4rem 0;
  text-align: center;
  background: #222; }

.l-footer .footer-navi-list {
  display: table;
  width: 100%;
  max-width: 65rem;
  margin: 0 auto 5rem;
  vertical-align: middle; }

.l-footer .footer-navi-list li {
  display: table-cell; }

.l-footer .footer-navi-list li a {
  display: block;
  color: #fff; }

.l-footer .footer-sns {
  display: table;
  margin: 4rem auto 0; }

.l-footer .footer-sns li {
  display: table-cell; }

.l-footer .footer-sns li a {
  padding: 0 1.3rem; }

.l-footer .add {
  padding: 0 0 1rem;
  font-size: 1rem;
  color: #7f7f7f; }

@media screen and (max-width: 768px) {
  /* 768px以下の場合 */
  .sp {
    display: block; }
  .pc {
    display: none; }
  /*==============================
  ヘッダー
  ==============================*/
  .l-header .header-logo {
    width: 80%; }
  .l-header .right-box {
    width: 20%; }
  .l-header .right-box .menu-trigger,
  .l-header .right-box .menu-trigger span {
    display: inline-block;
    transition: all .4s;
    box-sizing: border-box; }
  .l-header .right-box .menu-trigger {
    display: block;
    position: relative;
    float: right;
    width: 50px;
    height: 44px; }
  .l-header .right-box .menu-trigger span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #555965;
    border-radius: 4px; }
  .l-header .right-box .menu-trigger span:nth-of-type(1) {
    top: 0; }
  .l-header .right-box .menu-trigger span:nth-of-type(2) {
    top: 20px; }
  .l-header .right-box .menu-trigger span:nth-of-type(3) {
    bottom: 0; }
  .l-header .right-box .menu-trigger.active span:nth-of-type(1) {
    transform-origin: 100% 0;
    -webkit-transform: translateY(0px) rotate(-45deg);
    transform: translateY(0px) rotate(-45deg); }
  .l-header .right-box .menu-trigger.active span:nth-of-type(2) {
    opacity: 0; }
  .l-header .right-box .menu-trigger.active span:nth-of-type(3) {
    transform-origin: 100% 100%;
    -webkit-transform: translateY(0px) rotate(45deg);
    transform: translateY(0px) rotate(45deg); }
  .l-header .l-nav {
    display: none; }
  .l-header .sp-nav {
    display: none;
    position: absolute;
    width: 100%;
    top: 10rem;
    right: 0;
    z-index: 5; }
  .l-header .sp-nav .nav-list {
    background: rgba(0, 0, 0, 0.9);
    display: block; }
  .l-header .sp-nav .nav-list > li {
    width: 100%;
    text-align: center;
    border: none; }
  .l-header .sp-nav .nav-list > li a {
    display: block;
    width: 100%; }
  .l-header .sp-nav .child {
    display: none;
    position: relative; }
  .l-mv {
    max-height: 260px;
    height: 35vw; }
  /*==============================
  コンテンツ
  ==============================*/
  .l-main {
    float: none;
    width: 100%;
    margin: 0;
    padding: 0; }
  .l-side {
    float: none;
    margin: 4rem 0 0;
    width: 100%; }
  .l-side .side-top,
  .l-side .facebook-box,
  .l-side .twitter-box {
    text-align: center; }
  .l-side .searchform {
    display: table;
    width: 100%; }
  .l-side .search {
    display: table-cell;
    max-width: 81%; }
  .l-side .seach-btn {
    display: table-cell;
    width: 19%;
    background-color: #9D1715;
    background-size: contain; } }

.l-copyright small {
  display: inline-block;
  margin: 35px 0 10px;
  color: #7f7f7f;
  font-size: 10px; }

.form {
  margin-top: 30px; }
  .form form label {
    display: block;
    margin-right: 10px; }
  .form form input {
    vertical-align: 1px; }
  .form form button {
    cursor: pointer;
    border: 0;
    background-color: #000; }
  .form textarea {
    font-size: 14px;
    box-sizing: border-box;
    width: 100%;
    padding: 10px 10px;
    min-height: 100px;
    border: 1px solid #ccc;
    border-radius: 3px;
    appearance: none;
    box-sizing: border-box; }
    .form textarea::-webkit-input-placeholder {
      color: #ccc; }
  .form .form__inner {
    flex-wrap: wrap;
    width: 100%; }
  .form .form__elm {
    width: 100%;
    margin-bottom: 20px; }
  .form .form__name {
    font-size: 14px;
    margin-bottom: 3px;
    font-weight: bold; }
  .form .form__submit {
    margin-top: 0px;
    text-align: center; }
    .form .form__submit input[type='submit'] {
      background: #f53243;
      -webkit-appearance: none;
      border-radius: 0;
      color: #FFF;
      border: none;
      border-bottom: 3px solid #c11121;
      padding: 10px 20px;
      margin: 20px 0;
      cursor: pointer;
      width: 60%;
      font-size: 16px;
      display: inline-block; }
    .form .form__submit button {
      width: 250px;
      padding: 15px;
      box-sizing: border-box;
      color: #fff;
      font-size: 16px;
      border-radius: 3px; }
  .form input[type=radio], .form input[type=checkbox] {
    display: none; }
  .form input[type=radio]:checked + .radio:before {
    opacity: 1; }
  .form input[type=checkbox]:checked + .checkbox:before {
    opacity: 1; }
  .form .radio {
    box-sizing: border-box;
    -webkit-transition: background-color 0.2s linear;
    transition: background-color 0.2s linear;
    position: relative;
    display: inline-block;
    padding-left: 20px;
    border-radius: 8px;
    vertical-align: middle;
    cursor: pointer; }
    .form .radio:after {
      -webkit-transition: border-color 0.2s linear;
      transition: border-color 0.2s linear;
      position: absolute;
      top: 50%;
      left: 0px;
      display: block;
      margin-top: -9px;
      width: 15px;
      height: 15px;
      border: 1px solid rgba(0, 0, 0, 0.3);
      background-color: #fff;
      border-radius: 100%;
      content: ''; }
    .form .radio:before {
      -webkit-transition: opacity 0.2s linear;
      transition: opacity 0.2s linear;
      position: absolute;
      top: 50%;
      left: 5px;
      display: block;
      margin-top: -4px;
      width: 7px;
      height: 7px;
      background: #000;
      content: '';
      opacity: 0;
      z-index: 10;
      border-radius: 10px; }
  .form .checkbox {
    box-sizing: border-box;
    -webkit-transition: background-color 0.2s linear;
    transition: background-color 0.2s linear;
    position: relative;
    display: inline-block;
    padding-top: 5px;
    padding-left: 26px;
    border-radius: 8px;
    vertical-align: middle;
    cursor: pointer; }
    .form .checkbox span {
      display: inline-block;
      padding-top: 5px; }
    .form .checkbox:after {
      -webkit-transition: border-color 0.2s linear;
      transition: border-color 0.2s linear;
      position: absolute;
      top: 50%;
      left: 0px;
      display: block;
      margin-top: -9px;
      width: 20px;
      height: 20px;
      border: 1px solid rgba(0, 0, 0, 0.3);
      background-color: #fff;
      border-radius: 2px;
      content: ''; }
    .form .checkbox:before {
      transition: opacity 0.2s linear;
      position: absolute;
      top: 50%;
      left: 7px;
      display: block;
      margin-top: -8px;
      width: 5px;
      height: 13px;
      border-right: 3px solid #000;
      border-bottom: 3px solid #000;
      content: '';
      opacity: 0;
      transform: rotate(45deg);
      z-index: 10; }

/* ===========================================================
// #contents
===========================================================*/
/* ===========================================================
===========================================================*/
@media all and (min-width: 768px) {
  .l-header {
    background-color: #fff;
    padding: 14px;
    padding-top: 6px;
    height: 80px;
    min-width: 1300px; }
    .l-header .header__banner {
      margin-top: -8px;
      margin-right: 0px;
      width: 440px; }
      .l-header .header__banner a {
        display: inline-block; }
        .l-header .header__banner a:first-child {
          margin-right: 5px; }
  .l-header .h1-area {
    margin: 0;
    padding: 0;
    height: auto;
    color: #736f6f;
    font-size: 10px;
    background-color: transparent;
    margin-bottom: 2px; }
  .l-nav {
    display: flex !important; }
  .l-mv {
    margin-top: 81px; }
  .l-header .l-nav .nav-list {
    margin-top: 1px; }
  .l-header .l-nav .child {
    padding-top: 27px; }
  .mv .mv__inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between; }
  .mv .mv__image {
    width: 690px; }
  .mv .mv__banner {
    width: 470px;
    position: relative;
    left: 8px;
    padding-top: 20px; }
    .mv .mv__banner a {
      display: inline-block;
      margin-right: -5px; }
  .page {
    background-color: #fff; }
    .page .page__inner {
      padding: 2rem; }
  .relation {
    margin-top: 60px; }
    .relation .relation__elm {
      margin-bottom: 20px;
      padding: 2rem;
      -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
      background: #fff; }
      .relation .relation__elm a {
        text-decoration: none; }
  .diagnosis {
    padding-top: 20px;
    font-size: 14px; }
    .diagnosis .diagnosis__image {
      text-align: center; }
    .diagnosis .form {
      padding: 0 20px; }
    .diagnosis #trouble {
      margin-top: 80px;
      border-top: 4px solid #0ba1d9;
      width: calc(100% + 40px);
      margin-left: -20px; }
    .diagnosis #trouble .ico_tro {
      margin-top: 20px;
      text-align: center; }
    .diagnosis #trouble .ico_tro img {
      width: 60px;
      margin-top: -40px;
      position: relative; }
    .diagnosis #trouble .container {
      margin: 0 40px; }
    .diagnosis #trouble .img {
      position: relative;
      margin-top: -80px; }
    .diagnosis #trouble .msg {
      width: 65%; }
    .diagnosis #trouble .msg h3 {
      font-size: 180%;
      font-weight: bold;
      color: #0ba1d9;
      margin: 20px 0  15px 0; }
    .diagnosis #trouble .msg li {
      font-size: 150%;
      font-weight: bold;
      background: url(/wp-content/themes/mts_moneyflow_2016/assets/img/page/diagnosis/ico_check.png) no-repeat left top;
      background-size: 25px;
      padding-left: 40px;
      border-bottom: 1px solid #ddd;
      padding-bottom: 15px;
      margin-bottom: 15px; }
    .diagnosis #trouble .tro_hilig {
      background: #f1f1f1;
      padding: 30px;
      text-align: center; }
    .diagnosis #trouble .tro_hilig p {
      font-size: 150%;
      font-weight: bold; }
    .diagnosis #form_trouble .txt_troble_blue {
      margin-top: 20px;
      font-size: 160%;
      font-weight: bold;
      color: #0ba1d9;
      text-align: center; }
    .diagnosis #form_trouble .diagnosis {
      text-align: center;
      background: url(/wp-content/themes/mts_moneyflow_2016/assets/img/page/diagnosis/arrow05.png) no-repeat center bottom;
      background-size: auto 25px;
      padding-bottom: 25px; }
    .diagnosis #form_trouble .diagnosis span {
      display: inline-block;
      font-size: 220%;
      font-weight: bold;
      color: #f8bb00;
      border: 2px solid #f8bb00;
      padding: 10px 40px; }
    .diagnosis #form_trouble .txt_diag {
      font-weight: bold;
      text-align: center;
      font-size: 120%;
      margin: 15px 0 0 0; }
    .diagnosis .pic_l .img, .diagnosis .pic_r .msg {
      float: left;
      padding-right: 8px; }
    .diagnosis .pic_l .msg, .diagnosis .pic_r .img {
      float: right; }
    .diagnosis .flow_picl .img {
      float: left;
      margin-right: 15px;
      margin-bottom: 5px; }
    .diagnosis .flow_picr .img {
      float: right;
      margin-left: 15px;
      margin-bottom: 5px; }
  .spv {
    display: none; }
  .pcv {
    display: inline-block; } }

@media screen and (max-width: 767px) {
  .l-contents {
    overflow: hidden; }
  .l-header {
    background-color: #fff;
    height: 70px; }
    .l-header .h1-area {
      margin-top: 2px;
      margin-bottom: 0px;
      color: #736f6f;
      background-color: transparent; }
    .l-header .header-logo {
      width: 170px; }
      .l-header .header-logo a {
        display: block; }
        .l-header .header-logo a img {
          width: 100%; }
    .l-header .right-box .menu-trigger {
      margin-top: -3px;
      width: 35px;
      height: 27.5px; }
      .l-header .right-box .menu-trigger span {
        height: 3px;
        border-radius: 3px; }
      .l-header .right-box .menu-trigger span:nth-of-type(2) {
        top: 50%;
        transform: translate(0, -50%); }
    .l-header .sp-nav {
      top: 70px; }
      .l-header .sp-nav .nav-list {
        padding: 5px 0; }
      .l-header .sp-nav .nav-list > li a {
        color: #fff;
        padding: 10px 0; }
  .l-mv {
    margin-top: 70px;
    background: url(../img/layout/header/mv_bg.png) no-repeat center top;
    background-size: cover;
    height: auto;
    max-height: none; }
  .mv {
    width: 100%; }
    .mv .mv__image {
      margin-top: 10px; }
    .mv .mv__banner {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      width: calc(101.5%);
      margin-left: -0.5%;
      margin-bottom: 10px; }
      .mv .mv__banner a {
        display: inline-block;
        width: 50%; }
        .mv .mv__banner a img {
          width: 100%; }
  .diagnosis {
    padding-top: 20px;
    font-size: 14px; }
    .diagnosis #trouble {
      margin-top: 80px;
      border-top: 4px solid #0ba1d9;
      width: calc(100% + 40px);
      margin-left: -20px; }
    .diagnosis #trouble .ico_tro {
      margin-top: 20px;
      text-align: center; }
    .diagnosis #trouble .ico_tro img {
      width: 60px;
      margin-top: -40px;
      position: relative; }
    .diagnosis #trouble .container {
      margin: 0 40px; }
    .diagnosis #trouble .img {
      position: relative;
      margin-top: -80px; }
    .diagnosis #trouble .msg {
      width: 65%; }
    .diagnosis #trouble .msg h3 {
      font-size: 180%;
      font-weight: bold;
      color: #0ba1d9;
      margin: 20px 0  15px 0; }
    .diagnosis #trouble .msg li {
      font-size: 150%;
      font-weight: bold;
      background: url(/wp-content/themes/mts_moneyflow_2016/assets/img/page/diagnosis/ico_check.png) no-repeat left top;
      background-size: 25px;
      padding-left: 40px;
      border-bottom: 1px solid #ddd;
      padding-bottom: 15px;
      margin-bottom: 15px; }
    .diagnosis #trouble .tro_hilig {
      background: #f1f1f1;
      padding: 30px;
      text-align: center; }
    .diagnosis #trouble .tro_hilig p {
      font-size: 150%;
      font-weight: bold; }
    .diagnosis #form_trouble .txt_troble_blue {
      margin-top: 20px;
      font-size: 160%;
      font-weight: bold;
      color: #0ba1d9;
      text-align: center; }
    .diagnosis #form_trouble .diagnosis {
      text-align: center;
      background: url(/wp-content/themes/mts_moneyflow_2016/assets/img/page/diagnosis/arrow05.png) no-repeat center bottom;
      background-size: auto 25px;
      padding-bottom: 25px; }
    .diagnosis #form_trouble .diagnosis span {
      display: inline-block;
      font-size: 220%;
      font-weight: bold;
      color: #f8bb00;
      border: 2px solid #f8bb00;
      padding: 10px 40px; }
    .diagnosis #form_trouble .txt_diag {
      font-weight: bold;
      text-align: center;
      font-size: 120%;
      margin: 15px 0 0 0; }
    .diagnosis .pic_l .img, .diagnosis .pic_r .msg {
      float: left;
      padding-right: 8px; }
    .diagnosis .pic_l .msg, .diagnosis .pic_r .img {
      float: right; }
    .diagnosis .flow_picl .img {
      float: left;
      margin-right: 15px;
      margin-bottom: 5px; }
    .diagnosis .flow_picr .img {
      float: right;
      margin-left: 15px;
      margin-bottom: 5px; }
  .pcv {
    display: none; }
  .spv {
    display: inline-block; } }
