
.l-contents{
	overflow: hidden;
}

.l-header{
	background-color: #fff;
	height: 70px;
	.h1-area {
		margin-top: 2px;
		margin-bottom: 0px;
		color: #736f6f;
		background-color: transparent;
	}
	.header-logo{
		width: 170px;
		a{
			display: block;
			img{
				width: 100%;
			}
		}
	}
	.right-box .menu-trigger{
		margin-top: -3px;
		width: 35px;
		height: 27.5px;
		span{
			height: 3px;
			border-radius: 3px;
		}
		span:nth-of-type(2) {
			top: 50%;
			transform: translate(0, -50%);
		}
	}
	.sp-nav{
		top: 70px;
		.nav-list{
			padding:5px 0;
		}
		.nav-list > li a{
			color: #fff;
			padding: 10px 0;
		}
	}

}


.l-mv{
	margin-top: 70px;
	background: url(../img/layout/header/mv_bg.png) no-repeat center top;
	background-size: cover;
	height: auto;
	max-height: none;

}
.mv{
	width: 100%;
	// overflow: hidden;
	// padding-bottom: 10px;
	.mv__image{
		margin-top: 10px;
	}
	.mv__banner{
		margin-top: 20px;
		display: flex;
		flex-wrap:wrap;
		width: calc(101.5%);
		margin-left: -0.5%;
		margin-bottom: 10px;
		a{
			display: inline-block;
			width: 50%;
			img{
				width: 100%;
			}
		}
	}
}








.diagnosis{
	padding-top: 20px;
	font-size: 14px;
	#trouble{
		margin-top: 80px;
		border-top: 4px solid #0ba1d9;
		width: calc(100% + 40px);
		margin-left: -20px;
	}
	#trouble .ico_tro{
		margin-top: 20px;
		text-align: center;
	}
	#trouble .ico_tro img{
		width: 60px;
		margin-top: -40px;
		position: relative;
	}
	#trouble .container{ margin: 0 40px; }
	#trouble .img{
		position: relative;
		margin-top: -80px;
	}
	#trouble .msg{ width: 65%;}
	#trouble .msg h3{
		font-size: 180%;
		font-weight: bold;
		color: #0ba1d9;
		margin: 20px 0  15px 0;
	}
	#trouble .msg li{
		font-size: 150%;
		font-weight: bold;
		background: url(/wp-content/themes/mts_moneyflow_2016/assets/img/page/diagnosis/ico_check.png) no-repeat left top;
		background-size: 25px;
		padding-left: 40px;
		border-bottom: 1px solid #ddd;
		padding-bottom: 15px;
		margin-bottom: 15px;
	}
	#trouble .tro_hilig{
		background: #f1f1f1;
		padding: 30px;
		text-align: center;
	}
	#trouble .tro_hilig p{

		font-size: 150%;
		font-weight: bold;
	}
	#form_trouble .txt_troble_blue{
		margin-top: 20px;
		font-size: 160%;
		font-weight: bold;
		color: #0ba1d9;
		text-align: center;
	}
	#form_trouble .diagnosis{
		text-align: center;
		background: url(/wp-content/themes/mts_moneyflow_2016/assets/img/page/diagnosis/arrow05.png) no-repeat center bottom;
		background-size: auto 25px;
		padding-bottom: 25px;
	}
	#form_trouble .diagnosis span{
		display: inline-block;
		font-size: 220%;
		font-weight: bold;
		color: #f8bb00;
		border: 2px solid #f8bb00;
		padding:10px 40px;
	}
	#form_trouble .txt_diag{
		font-weight: bold;
		text-align: center;
		font-size: 120%;
		margin: 15px 0 0 0 ;
	}
	.pic_l .img, .pic_r .msg {
		float: left;
		padding-right:8px;
	}
	.pic_l .msg, .pic_r .img {
		float: right;
	}

	.flow_picl .img{ float:left; margin-right: 15px; margin-bottom: 5px;}
	.flow_picr .img{ float:right; margin-left: 15px; margin-bottom: 5px;}

}

